import React, { useState, useEffect } from 'react';
import AddInstructor from './AddInstructor';
import EditInstructor from './EditInstructor';

function ManageInstructors() {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    // Fetch the list of instructors to populate the list
    fetch('https://sxg7864.uta.cloud/phase3-php/retrieve-instructors.php')
      .then((response) => response.json())
      .then((data) => {
        setInstructors(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <center><h1>Manage Instructors</h1></center>

      <AddInstructor refreshInstructors={() => {
        // Implement a function to refresh the instructors list after adding or editing
        fetch('https://sxg7864.uta.cloud/phase3-php/retrieve-instructors.php')
          .then((response) => response.json())
          .then((data) => {
            setInstructors(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }} />

      <EditInstructor instructors={instructors} refreshInstructors={() => {
        // Implement a function to refresh the instructors list after adding or editing
        fetch('https://sxg7864.uta.cloud/phase3-php/retrieve-instructors.php')
          .then((response) => response.json())
          .then((data) => {
            setInstructors(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }} />

      {/* Render the list of instructors */}
      <ul>
        {instructors.map((instructor) => (
          <li key={instructor.id}>
            {instructor.name} - {instructor.email} - {instructor.department}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ManageInstructors;
