import React, { useState } from 'react';
import './style.css'; // Import your CSS file
import signupImage from './signup.jpg'; // Import your image
import { Link } from 'react-router-dom';

function Signup() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'role1',
    fullName: '',
    birthdate: '',
  });

  const [passwordError, setPasswordError] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    // Password validation
    const passwordValidations = validatePassword(formData.password);
    if (passwordValidations.length > 0) {
      setPasswordError(passwordValidations.join('\n'));
      return;
    }

    // Reset password error if it was previously set
    setPasswordError('');


    try {                         
      const response = await fetch('https://sxg7864.uta.cloud/phase3-php/signup.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setRegistrationStatus(data.message);
      } else {
        setRegistrationStatus('Error registering. Please try again.');
      }
    } catch (error) {
      setRegistrationStatus(error);
    }
  };

  const validatePassword = (password) => {
    const validations = [];

    if (password.length < 8) {
      validations.push('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(password)) {
      validations.push('Password must contain an uppercase letter.');
    }
    if (!/[a-z]/.test(password)) {
      validations.push('Password must contain a lowercase letter.');
    }
    if (!/\d/.test(password)) {
      validations.push('Password must contain a number.');
    }
    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) {
      validations.push('Password must contain a special character ($&+,:;=?@#|\'<>.^*()%!-).');
    }

    return validations;
  };

  return (
    <div>
    <div className="container">
        <div className="content">
          <div className="image">
            <img src={signupImage} alt="Image Description" />
          </div>
          <div className="container">
            <center><h2>Sign Up</h2></center><br />
          <div>
            <p>
              If you already have an account,{' '}
              <Link to="/signin">login here</Link>
            </p><br></br>
          </div>
          <form onSubmit={handleFormSubmit} method="post">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              required
              value={formData.username}
              onChange={handleInputChange}
            />
<br></br><br></br>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
<br></br><br></br>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={formData.password}
              onChange={handleInputChange}
            />
<br></br><br></br>
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              required
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
<br></br><br></br>
            <div className="form-group">
              <label htmlFor="role">Select Role</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="role1">Student</option>
                <option value="role2">Admin</option>
                <option value="role3">QA Officer</option>
                <option value="role4">Instructor</option>
                <option value="role5">Program Coordinator</option>
              </select>
            </div>

            <label htmlFor="fullName">Full Name:</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              required
              value={formData.fullName}
              onChange={handleInputChange}
            />
<br></br><br></br>
            <label htmlFor="birthdate">Birthdate:</label>
            <input
              type="date"
              id="birthdate"
              name="birthdate"
              required
              value={formData.birthdate}
              onChange={handleInputChange}
            />
<br></br><br></br>
            {passwordError && <p className="error-message">{passwordError}</p>}
            {registrationStatus && (
              <p className="registration-status">{registrationStatus}</p>
            )}
          <br></br><br></br>
            <button className="login-button" type="submit">
              Register
            </button>
          </form>
        </div>
      </div>
    </div></div>
  );
}

export default Signup;
