import React from 'react';
import './style.css'; // Import your CSS file

function Services() {
  return (
    <div>
      

      <br />
      <br />
      <br />

      <div className="content4">
        <h1>Our Services</h1>

        <div className="service">
          <h2>Academic Program Management</h2>
          <p>
            Our Academic Program Management services provide comprehensive solutions to ensure the successful operation and continuous improvement of your educational programs. We work closely with institutions to align programs with industry standards, monitor performance, and drive excellence in education.
          </p>
        </div>

        <div className="service">
          <h2>Curriculum Development</h2>
          <p>
            Our Curriculum Development services focus on designing and updating curricula that meet the demands of today's educational landscape. We collaborate with educators to create engaging, up-to-date, and relevant course content that prepares students for success in their chosen fields.
          </p>
        </div>

        <div className="service">
          <h2>Assessment and Evaluation</h2>
          <p>
            Effective assessment and evaluation are at the core of educational excellence. Our Assessment and Evaluation services enable institutions to measure program and student performance accurately. We provide tools and insights for data-driven decision-making and continuous improvement.
          </p>
        </div>

        <br />
        <br />
      </div>

      
    </div>
  );
}

export default Services;
