import React from 'react';
import './style.css'; // Import your CSS file

function ProgramCoordinatorManageInstructors() {
  const handleAddInstructor = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const name = e.target.querySelector('#instructor-name').value;
    const email = e.target.querySelector('#instructor-email').value;
    const department = e.target.querySelector('#instructor-department').value;

    // Add your logic for adding an instructor here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Instructor added successfully!\nName: ${name}\nEmail: ${email}\nDepartment: ${department}`);
  };

  const handleEditInstructor = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const instructorId = e.target.querySelector('#instructor-id').value;
    const newName = e.target.querySelector('#instructor-new-name').value;
    const newEmail = e.target.querySelector('#instructor-new-email').value;
    const newDepartment = e.target.querySelector('#instructor-new-department').value;

    // Add your logic for editing an instructor here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Instructor edited successfully!\nID: ${instructorId}\nNew Name: ${newName}\nNew Email: ${newEmail}\nNew Department: ${newDepartment}`);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Manage Instructors</h1><br />
          <p>Welcome to the Manage Instructors section for Program Coordinators.</p><br />

          {/* Sample content for managing instructors */}
          <h2>Add Instructor</h2><br />
          <form id="add-instructor-form" onSubmit={handleAddInstructor}>
            <label htmlFor="instructor-name">Name:</label>
            <input type="text" id="instructor-name" name="name" required />
            <br />
            <label htmlFor="instructor-email">Email:</label>
            <input type="email" id="instructor-email" name="email" required />
            <br />
            <label htmlFor="instructor-department">Department:</label>
            <input type="text" id="instructor-department" name="department" required />
            <br />
            <button type="submit">Add Instructor</button>
          </form>
          <br />
          <h2>Edit Instructor</h2><br />
          <form onSubmit={handleEditInstructor}>
            <label htmlFor="instructor-id">Select Instructor:</label>
            <select id="instructor-id" name="instructor-id">
              <option value="1">Instructor 1</option>
              <option value="2">Instructor 2</option>
              <option value="3">Instructor 3</option>
            </select>
            <br />
            <label htmlFor="instructor-new-name">New Name:</label>
            <input type="text" id="instructor-new-name" name="new-name" />
            <br />
            <label htmlFor="instructor-new-email">New Email:</label>
            <input type="email" id="instructor-new-email" name="new-email" />
            <br />
            <label htmlFor="instructor-new-department">New Department:</label>
            <input type="text" id="instructor-new-department" name="new-department" />
            <br />
            <button type="submit">Edit Instructor</button>
          </form>

          {/* Add more instructor management content as needed */}
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinatorManageInstructors;
