import React from 'react';
import './style.css'; // Import your CSS file

function AdministratorReviews() {
  return (
    <div>
     
      <div className="container">
        <div className="content1">
          <h1>Reviews</h1><br />
          <p>Welcome to the Reviews section for Administrators.</p><br />
          {/* Sample content for reviews */}
          <h2>Course Reviews</h2>
          <p>Here, you can view and manage course reviews. You can analyze feedback from students and instructors, identify areas for improvement, and take necessary actions to enhance the quality of courses.</p>

          {/* Add more review-related content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default AdministratorReviews;
