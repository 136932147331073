import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

function AdminDashboard() {
  const [chatHistory, setChatHistory] = useState([
    { role: 'admin', message: 'Hi! How can I assist you today?' },
  ]);

  const predefinedQuestions = {
    'Hi': 'Hello.',
    'How do I create a new course?': 'To create a new course, you can navigate to the "Handle Course" section and follow the instructions.',
    'Can you help me with student management?': 'Sure, you can go to the "Manage Students" section for student management.',
    'Tell me about QA Officer reports.': 'QA Officer reports contain information about course quality and performance.',
    'What are my permissions as an admin?': 'As an admin, you have various permissions related to course and user management.',
    'How can I add a QA Officer?': 'To add a QA Officer, go to the "Manage QA Officer" section and follow the steps.',
    'How do I view performance analytics?': 'You can view performance analytics in the "Performance" section.',
    'Where can I find course reviews?': 'Course reviews are available in the "Reviews" section.',
    'How do I assign students to a course?': 'You can assign students in the "Assigned Students" section.',
    'Tell me more about course permissions.': 'Course permissions allow you to manage access and actions within a course.',
    'What is the purpose of QA Officer reports?': 'QA Officer reports help monitor and improve course quality.',
    'How can I manage student reports?': 'Student reports can be managed in the "Reports" section.',
    'How do I edit a course?': 'Course editing can be done in the "Handle Course" section.',
    'How can I remove a QA Officer?': 'To remove a QA Officer, go to the "Manage QA Officer" section and follow the steps.',
    'How to assign course permissions?': 'Course permissions can be assigned in the "Permissions" section.',
    'Where can I find admin help?': 'You are already in the chat, feel free to ask any questions!',
    'Bye': 'Bye.Have a good day',
  };

  const sendMessage = (message) => {
    const newChat = [...chatHistory, { role: 'user', message }];
    setChatHistory(newChat);

    // Simulate admin response
    setTimeout(() => {
      const response = getResponse(message);
      const newChatWithResponse = [...newChat, { role: 'admin', message: response }];
      setChatHistory(newChatWithResponse);
    }, 1000);
  };

  const getResponse = (question) => {
    // Provide responses to specific questions or a default message
    return predefinedQuestions[question] || "I'm sorry, I don't have information about that. Please ask another question.";
  };

  const handleSendMessage = () => {
    const chatInput = document.getElementById('chat-input');
    const messageText = chatInput.value.trim();

    if (messageText) {
      sendMessage(messageText);
      chatInput.value = '';
    }
  };

  const handleLogout = () => {
    window.location.href = '/';
  };

  return (
    <div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="content1">
        <h1>Welcome to Administrator Dashboard</h1>
        <div className="dashboard">
          <div className="section common-box">
            <h2>Coordinator</h2>
            <div className="button-container1">
              <Link to="/AdministratorReviews" className="button">
                Reviews
              </Link>
              <Link to="/AssignedStudents" className="button">
                Assigned Students
              </Link>
              <Link to="/managestudents" className="button">
                Handle Course
              </Link>
            </div>
          </div>

          <div className="section common-box">
            <h2>Student</h2>
            <div className="button-container1">
              <Link to="/managecourses" className="button">
                Manage Course
              </Link>
              <Link to="/ReportStudent" className="button">
                Reports
              </Link>
              <Link to="/ViewAnalytics" className="button">
                Performance
              </Link>
              <Link to="/managestudents" className="button">
                Manage Students
              </Link>
            </div>
          </div>

          <div className="section common-box">
            <h2>QA Officer</h2>
            <div className="button-container1">
              <Link to="/AdminAssignedCourse" className="button">
                Assigned Course
              </Link>
              <Link to="/ReportStudent" className="button">
                Reports
              </Link>
              <Link to="/manageqa" className="button">
                Manage QA Officer
              </Link>
              <Link to="/AdminPermissions" className="button">
                Permissions
              </Link>
            </div>
          </div>
        </div>

        <div className="chat-box1 common-box">
        <h2>Chat Box</h2>
        <div className="chat-messages">
          {chatHistory.map((chat, index) => (
            <div key={index} className={`message from-${chat.role}`}>
              <span className="message-text">{chat.message}</span>
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            id="chat-input"
            placeholder="Type your message..."
          />
          <button id="send-button" onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
);
}

export default AdminDashboard;
