import React from 'react';
import './style.css'; // Import your CSS file

function CreateCourse() {
  const redirectToPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  const handleCreateCourseSubmit = (e) => {
    e.preventDefault();
    const courseId = e.target['course-id'].value;
    const courseName = e.target['course-name'].value;

    // You can perform additional actions here, like making an API request to create the course

    // Display an alert to confirm the course creation
    alert(`Course Created:\nCourse ID: ${courseId}\nCourse Name: ${courseName}`);

    // Optionally, you can reset the form fields
    e.target.reset();
  };

  return (
    <div>
     
      <div className="container">
        <h2>Create Course</h2>
        <div className="content1">
          <br />
          <br />
          <form onSubmit={handleCreateCourseSubmit}>
            <label htmlFor="course-id">Course ID:</label>
            <input type="text" id="course-id" name="course-id" required />
            <br /><br />
            <label htmlFor="course-name">Course Name:</label>
            <input type="text" id="course-name" name="course-name" required />

            {/* Add more fields for course details here */}
            <button className="button" type="submit">
              Create Course
            </button>
          </form>
        </div>
      </div>
      
    </div>
  );
}

export default CreateCourse;
