import React from 'react';
import './style.css'; // Import your CSS file

function AdminAssignedCourse() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Assigned Course</h1><br />
          <p>Welcome to the Assigned Course section for Administrators.</p>
          <br />
          {/* Sample content for managing assigned courses */}
          <h2>Manage Assigned Courses</h2>
          <p>In this section, you can view and manage courses that are assigned to various courses within your educational institution. You have the flexibility to assign or reassign courses, track their progress, and handle any related administrative tasks.</p>
          
          {/* Add more content for managing assigned courses as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default AdminAssignedCourse;
