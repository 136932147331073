import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerProgramReview() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
        <center>
      <h3>Program Review</h3></center>
     </div>
     <div>
      <p>
        Welcome to the Program Review page. Explore the reviews and feedback for our Computer Science and Data Science programs below.
      </p>
      <h4>Computer Science Program</h4>
      <p>
        <strong>Program Description:</strong> Our Computer Science program offers a comprehensive curriculum that covers various aspects of computer science, including software development, algorithms, and data structures.
      </p>
      <p>
        <strong>Reviews:</strong>
      </p>
      <ul>
        <li>"I found the Computer Science program to be very challenging and rewarding. The faculty is knowledgeable and supportive." - Jane S.</li>
        <li>"The program provided a solid foundation for a career in technology. I enjoyed the hands-on projects and labs." - John D.</li>
      </ul>

      <h4>Data Science Program</h4>
      <p>
        <strong>Program Description:</strong> Our Data Science program equips students with the skills to analyze and derive insights from data, making them valuable assets in the world of data-driven decision-making.
      </p>
      <p>
        <strong>Reviews:</strong>
      </p>
      <ul>
        <li>"The Data Science program exceeded my expectations. I gained a deep understanding of data analysis, machine learning, and data visualization." - Sarah M.</li>
        <li>"This program opened up exciting career opportunities in the field of data science for me. The practical projects were invaluable." - Michael P.</li>
      </ul>

      <p>
        These reviews reflect the experiences of our students in the Computer Science and Data Science programs. We are committed to providing exceptional education in these fields.
      </p>
    
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerProgramReview;
