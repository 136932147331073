import React from 'react';
import './style.css'; // Import your CSS file

function Edit() {
  const redirectToPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  const handleEditProfileSubmit = (e) => {
    e.preventDefault();

    // You can access the form values by using e.target
    const name = e.target.name.value;
    const email = e.target.email.value;
    const department = e.target.department.value;
    const officeHours = e.target['office-hours'].value;

    // You can perform actions here, like making an API request to save the changes

    // Display an alert to confirm the changes
    alert('Profile changes saved successfully!');

    // Optionally, you can reset the form fields
    e.target.reset();
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h2>Edit Instructor Profile</h2>
          <br />
          <br />
          <br />
          <form onSubmit={handleEditProfileSubmit}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" defaultValue="SAM" />
            <br /><br />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" defaultValue="sam123@example.com" />
            <br /><br />

            <label htmlFor="department">Department:</label>
            <input type="text" id="department" name="department" defaultValue="Computer Science" />
            <br /><br />

            <label htmlFor="office-hours">Office Hours:</label>
            <input
              type="text"
              id="office-hours"
              name="office-hours"
              defaultValue="Monday and Wednesday, 2:00 PM - 4:00 PM"
            />
            <br /><br />
            
            {/* Add more fields for other instructor details that can be edited */}
            <button className="button" type="submit">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Edit;
