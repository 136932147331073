import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerFeedback() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Feedback</h1>
          <br />
          <p>Welcome to the Feedback section for QA Officers.</p>
          <br />

          {/* Sample feedback content */}
          <h2>Feedback Collection</h2>
          <p>Collect feedback from various stakeholders for program improvement.</p>
          <br />
          <h2>Analysis</h2>
          <p>Analyze feedback data and create action plans.</p>

          {/* Add more feedback content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerFeedback;
