import React from 'react';
import './style.css'; // Import your CSS file

function EditStudentProfile() {
  const handleEditProfileSubmit = (e) => {
    e.preventDefault();

    // You can access the form values by using e.target
    const name = e.target.name.value;
    const email = e.target.email.value;
    const department = e.target.department.value;
    const status = e.target.status.value;

    // You can perform actions here, like making an API request to save the changes

    // Display an alert to confirm the changes
    alert('Student profile changes saved successfully!');

    // Optionally, you can reset the form fields
    e.target.reset();
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Edit Student Profile</h1>

          {/* Edit Student Profile Form */}
          <form onSubmit={handleEditProfileSubmit}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" defaultValue="Ram" />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" defaultValue="ram@example.com" />

            <label htmlFor="department">Department:</label>
            <input type="text" id="department" name="department" defaultValue="Computer Science" />

            <label htmlFor="status">Enrollment Status:</label>
            <select id="status" name="status">
              <option value="Enrolled" selected>Enrolled</option>
              <option value="Not Enrolled">Not Enrolled</option>
            </select>

            {/* Add more fields for editing as needed */}

            <button className="button" type="submit">Save Changes</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditStudentProfile;
