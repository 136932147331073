import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerStudentSupport() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Student Support</h1>
          <br />
          <p>Welcome to the Student Support section for QA Officers.</p>
          <br />

          {/* Sample student support content */}
          <h2>Student Assistance</h2>
          <p>Provide support and assistance to students.</p>
          <br />
          <h2>Feedback Collection</h2>
          <p>Collect feedback from students and analyze their needs.</p>

          {/* Add more student support content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerStudentSupport;
