import React from 'react';
import './style.css'; // Import your CSS file

function QAOfficerManageCourses() {
  const handleAddCourse = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const courseCode = e.target.querySelector('#course-code').value;
    const courseName = e.target.querySelector('#course-name').value;

    // Add your logic for adding a course here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Course added successfully!\nCourse Code: ${courseCode}\nCourse Name: ${courseName}`);
  };

  const handleEditCourse = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const courseCode = e.target.querySelector('#course-code').value;
    const newCourseName = e.target.querySelector('#new-course-name').value;

    // Add your logic for editing a course here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Course edited successfully!\nCourse Code: ${courseCode}\nNew Course Name: ${newCourseName}`);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Manage Courses</h1><br />
          <p>Welcome to the Manage Courses section for QA Officers.</p><br />

          {/* Sample content for managing courses */}
          <h2>Add Course</h2><br />
          <form id="add-course-form" onSubmit={handleAddCourse}>
            <label htmlFor="course-code">Course Code:</label>
            <input type="text" id="course-code" name="course-code" required />
            <br />
            <label htmlFor="course-name">Course Name:</label>
            <input type="text" id="course-name" name="course-name" required />
            <br />
            <button type="submit">Add Course</button>
          </form>
          <br />
          <h2>Edit Course</h2><br />
          <form onSubmit={handleEditCourse}>
            <label htmlFor="course-code">Select Course:</label>
            <select id="course-code" name="course-code">
              <option value="CSE101">CSE101 - Introduction to Computer Science</option>
              <option value="MATH202">MATH202 - Calculus II</option>
              <option value="PHY301">PHY301 - Physics for Engineers</option>
            </select>
            <br />
            <label htmlFor="new-course-name">New Course Name:</label>
            <input type="text" id="new-course-name" name="new-course-name" />
            <br />
            <button type="submit">Edit Course</button>
          </form>

          {/* Add more course management content as needed */}
        </div>
      </div>
    </div>
  );
}

export default QAOfficerManageCourses;
