import React from 'react';
import './style.css'; // Import your CSS file

function AdministratorAssignedStudents() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Assigned Students</h1><br />
          <p>Welcome to the Assigned Students section for Administrators.</p><br />
          {/* Sample content for managing assigned students */}
          <h2>Manage Assigned Students</h2>
          <p>In this section, you can view and manage students who are assigned to various courses within your educational institution. You have the flexibility to assign or reassign students to courses, track their progress, and handle any related administrative tasks.</p>

          {/* Add more content for managing assigned students as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default AdministratorAssignedStudents;
