import React from 'react';
import './style.css'; // Import your CSS file

function CreateExam() {
  const redirectToPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  const handleCreateExamSubmit = (e) => {
    e.preventDefault();
    const examName = e.target['exam-name'].value;

    // You can perform additional actions here, like making an API request to create the exam

    // Display an alert to confirm the exam creation
    alert(`Exam Created:\nExam Name: ${examName}`);

    // Optionally, you can reset the form fields
    e.target.reset();
  };

  return (
    <div>
      <div className="container">
        <h2>Create Exam</h2>
        <div className="content1">
          <br />
          <br />
          <form onSubmit={handleCreateExamSubmit}>
            <label htmlFor="exam-name">Exam Name:</label>
            <input type="text" id="exam-name" name="exam-name" required />

            {/* Add more fields for exam details here */}
            <button className="button" type="submit">
              Create Exam
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateExam;
