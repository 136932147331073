import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerQualityStandards() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Quality Standards - QA Officer Dashboard</h1><br />
          <p>Welcome to the Quality Standards section for QA Officers.</p><br />

          {/* Sample quality standards content */}
          <h2>QA Framework</h2>
          <p>Access the quality assurance framework and guidelines.</p>
          <br />
          <h2>Compliance</h2>
          <p>Ensure compliance with educational quality standards and regulations.</p>

          {/* Add more quality standards content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerQualityStandards;
