import React, { useState } from 'react';
import './style.css'; // Import your CSS file

function AdministratorCreateCourse() {
  const [createCourseMessage, setCreateCourseMessage] = useState('');
  const [editCourseMessage, setEditCourseMessage] = useState('');

  const handleCreateCourseSubmit = (e) => {
    e.preventDefault();
    const courseName = e.target.courseName.value;
    const courseCode = e.target.courseCode.value;
    const courseDescription = e.target.courseDescription.value;
    const instructor = e.target.instructor.value;

    const message = `Create Course Form Data:
Course Name: ${courseName}
Course Code: ${courseCode}
Course Description: ${courseDescription}
Instructor: ${instructor}`;

    setCreateCourseMessage(message);

    // Display an alert
    alert(message);
  };

  const handleEditCourseSubmit = (e) => {
    e.preventDefault();
    const courseName = e.target.courseName.value;
    const courseCode = e.target.courseCode.value;
    const courseDescription = e.target.courseDescription.value;
    const instructor = e.target.instructor.value;

    const message = `Edit Course Form Data:
Course Name: ${courseName}
Course Code: ${courseCode}
Course Description: ${courseDescription}
Instructor: ${instructor}`;

    setEditCourseMessage(message);

    // Display an alert
    alert(message);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Create Course</h1>
          <p>Welcome to the Create Course section for Administrators.</p>
          <form onSubmit={handleCreateCourseSubmit}>
            <label htmlFor="courseNameCreate">Course Name:</label>
            <input type="text" id="courseNameCreate" name="courseName" required /><br />
            <label htmlFor="courseCodeCreate">Course Code:</label>
            <input type="text" id="courseCodeCreate" name="courseCode" required /><br />
            <label htmlFor="courseDescriptionCreate">Course Description:</label>
            <textarea id="courseDescriptionCreate" name="courseDescription" rows="4" required></textarea><br />
            <label htmlFor="instructorCreate">Assign Instructor:</label>
            <select id="instructorCreate" name="instructor">
              <option value="instructor1">Instructor 1</option>
              <option value="instructor2">Instructor 2</option>
              <option value="instructor3">Instructor 3</option>
            </select><br />
            <input type="submit" value="Create Course" />
          </form>
          <p>{createCourseMessage}</p>
        </div>
        <div className="content1">
          <h1>Edit Course</h1>
          <p>Welcome to the Edit Course section for Administrators.</p>
          <form onSubmit={handleEditCourseSubmit}>
            <label htmlFor="courseNameEdit">Course Name:</label>
            <input type="text" id="courseNameEdit" name="courseName" required /><br />
            <label htmlFor="courseCodeEdit">Course Code:</label>
            <input type="text" id="courseCodeEdit" name="courseCode" required /><br />
            <label htmlFor="courseDescriptionEdit">Course Description:</label>
            <textarea id="courseDescriptionEdit" name="courseDescription" rows="4" required></textarea><br />
            <label htmlFor="instructorEdit">Assign Instructor:</label>
            <select id="instructorEdit" name="instructor">
              <option value="instructor1">Instructor 1</option>
              <option value="instructor2">Instructor 2</option>
              <option value="instructor3">Instructor 3</option>
            </select><br />
            <input type="submit" value="Save Changes" />
          </form>
          <p>{editCourseMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default AdministratorCreateCourse;
