import React from 'react';
import './style.css'; // Import your CSS file

function ProgramCoordinatorReports() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Reports</h1><br />
          <p>Welcome to the Reports section for Program Coordinators.</p><br />
          {/* Sample content for viewing reports */}
          <h2>Program Reports</h2>
          <p>Access program-related reports and analytics here.</p><br /><br />
          <h2>Administrative Reports</h2>
          <p>View administrative reports and statistics.</p>

          {/* Add more report-related content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default ProgramCoordinatorReports;
