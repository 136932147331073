import React from 'react';
import './style.css'; // Import your CSS file


function About() {
 
  return (
    
    <div>
     

      <div className="content4">
        <h1>About Us</h1>
        <p>
          Welcome to MSC Educational Management, a leading institution dedicated to excellence in education and continuous improvement.
        </p>
        <h2>Our Mission</h2>
        <p>
          Our mission is to empower individuals through education, fostering intellectual growth, critical thinking, and lifelong learning. We strive to create a dynamic learning environment that nurtures creativity, innovation, and leadership skills.
        </p>
        <h2>Our Vision</h2>
        <p>
          Our vision is to be a global leader in education, recognized for our commitment to quality, inclusivity, and innovation. We aim to shape future leaders and professionals who make a positive impact on society.
        </p>
        <h2>Our Team</h2>
        <p>
          MSC Educational Management is led by a dedicated team of experienced educators, administrators, and industry experts. Our instructors are passionate about teaching and bring real-world experience to the classroom.
        </p>
        <h2>Commitment to Excellence</h2>
        <p>
          We are committed to maintaining the highest standards of academic excellence. Our programs are designed in collaboration with industry leaders to ensure they are up-to-date and relevant to the demands of the job market.
        </p>
        <h2>Performance Measurement</h2>
        <p>
          At MSC Educational Management, we recognize the importance of performance measurement and assessment. We continuously assess our programs and implement improvements to enhance the learning outcomes of our students.
        </p>
      </div>

      
    </div>
  );
}

export default About;
