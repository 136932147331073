import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './style.css'; // Import your CSS file

function QaOfficerDashboard() {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const predefinedQuestions = {
    'Hi': 'Hello! How can I assist you today?',
    'Bye': 'Goodbye! If you have more questions, feel free to ask.',
    'Tell me about reports': 'Reports provide insights into various aspects of the program.',
    'Explain program review': 'Program review is a comprehensive evaluation of the program\'s performance.',
    'What is student support?': 'Student support services aim to assist students in their academic journey.',
    'Tell me about feedback': 'Feedback mechanisms collect input from stakeholders for program improvement.',
    'Explain improvement initiatives': 'Improvement initiatives are actions taken to enhance program quality.',
    'What is faculty development?': 'Faculty development programs aim to enhance teaching and learning skills.',
    'Tell me about assessment tools': 'Assessment tools are used to evaluate student performance and program outcomes.',
    'Explain quality standards': 'Quality standards are benchmarks that ensure program quality.',
  };

  const sendMessage = (message) => {
    if (message.trim() === '') return; // Don't send empty messages

    const newChatMessages = [...chatMessages];
    newChatMessages.push({ text: message, sender: 'user' });
    setChatMessages(newChatMessages);

    const response = predefinedQuestions[message];
    if (response) {
      newChatMessages.push({ text: response, sender: 'admin' });
    }

    setChatMessages(newChatMessages);
    setNewMessage(''); // Clear the input field
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  const handleLogout = () => {
    window.location.href = '/'; // Redirect to the login page
  };

  return (
    <div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="content1">
        <h1>Welcome to QA Officer Dashboard</h1>
        <br />
        <div className="dashboard">
          <div className="section2">
            <h2>QA Officer</h2>
            <div className="button-container1">
              <Link to="/ReportQA" className="button">
                Reports
              </Link>
              <Link to="/QaOfficerProgramReview" className="button">
                Program Review
              </Link>
              <Link to="/QaOfficerStudentSupport" className="button">
                Student Support
              </Link>
              <Link to="/QaOfficerFeedback" className="button">
                Feedback
              </Link>
              <Link to="/QaOfficerImprovement" className="button">
                Improvement
              </Link>
              <Link to="/QaOfficerFacultyDevelopment" className="button">
                Faculty Development
              </Link>
              <Link to="/QaOfficerAssessmentTools" className="button">
                Tools for Assessment
              </Link>
              <Link to="/QaOfficerQualityStandards" className="button">
                Quality Standards
              </Link>
            </div>
          </div>

          <div className="chat-box2">
            <h2>Chat Box</h2>
            <div className="chat-messages">
              {chatMessages.map((message, index) => (
                <div key={index} className={`message from-${message.sender}`}>
                  <span className="message-text">{message.text}</span>
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                id="chat-input"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <button id="send-button" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QaOfficerDashboard;
