import React from 'react';
import './style.css'; // Import your CSS file

function AdminPermissions() {
  // Function to handle the form submission
  const handlePermissionSubmit = (e) => {
    e.preventDefault();
    const userName = e.target.userName.value;
    const permissionLevel = e.target.permissionLevel.value;

    // Display a confirmation message (you can replace this with your desired logic)
    alert(`Permission Added:\nUser: ${userName}\nPermission Level: ${permissionLevel}`);
    
    // Reset the form fields
    e.target.reset();
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Permissions</h1>
          <p>Welcome to the Permissions section for QA Officers.</p>
          
          {/* Sample content for managing permissions */}
          <h2>User Permissions</h2>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Permission Level</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Instructor 1</td>
                <td>Read-Only</td>
                <td><a href="#">Edit</a> | <a href="#">Delete</a></td>
              </tr>
              <tr>
                <td>Instructor 2</td>
                <td>Full Access</td>
                <td><a href="#">Edit</a> | <a href="#">Delete</a></td>
              </tr>
              <tr>
                <td>Coordinator 1</td>
                <td>Read-Only</td>
                <td><a href="#">Edit</a> | <a href="#">Delete</a></td>
              </tr>
              {/* Add more user permissions as needed */}
            </tbody>
          </table>

          <h2>Add New Permission</h2>
          <form onSubmit={handlePermissionSubmit}>
            <label htmlFor="userName">User:</label>
            <input type="text" id="userName" name="userName" required /><br />

            <label htmlFor="permissionLevel">Permission Level:</label>
            <select id="permissionLevel" name="permissionLevel" required>
              <option value="Read-Only">Read-Only</option>
              <option value="Full Access">Full Access</option>
            </select><br />

            <input type="submit" value="Add Permission" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminPermissions;
