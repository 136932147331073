import React from 'react';
import './style.css';
function AssignedStudents() {
  // Sample data for assigned students
  const assignedStudents = [
    { id: 1, name: 'John Doe', course: 'CS' },
    { id: 2, name: 'Jane Smith', course: 'DS' },
    { id: 3, name: 'Bob Johnson', course: 'WDM' },
    { id: 4, name: 'Alice Brown', course: 'ML' },
  ];

  return (
    <div>
      <center><h3>Assigned Students</h3></center>
      <div class="container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Course</th>
          </tr>
        </thead>
        <tbody>
          {assignedStudents.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.name}</td>
              <td>{student.course}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div></div>
  );
}

export default AssignedStudents;
