import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './style.css'; // Import your CSS file

function InstructorDashboard() {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [name, setName] = useState('SAM');
  const [email, setEmail] = useState('sam123@example.com');
  const [department, setDepartment] = useState('Computer Science');
  const [officeHours, setOfficeHours] = useState('Monday and Wednesday, 2:00 PM - 4:00 PM');

  const predefinedQuestions = {
    'Hi': 'Hello! How can I assist you today?',
    'Bye': 'Goodbye! If you have more questions, feel free to ask.',
    'Tell me about grading peers': 'Grading peers is part of the course evaluation process.',
    'Explain course creation': 'Course creation involves setting up the course content and structure.',
    'What is exam creation?': 'Exam creation allows you to generate and manage course exams.',
    'Tell me about student selection': 'Student selection refers to choosing students for specific actions or programs.',
    'Explain office hours': 'Office hours are dedicated times for student or peer interaction.',
    'How can I edit my profile?': 'You can edit your profile in the "Edit Profile" section.',
    'Tell me about exams available': 'Exams are listed in the dropdown for selection.',
    'How can I select a student?': 'Choose a student from the student dropdown to view their details.',
  };

  const sendMessage = (message) => {
    if (message.trim() === '') return; // Don't send empty messages

    const newChatMessages = [...chatMessages];
    newChatMessages.push({ text: message, sender: 'user' });
    setChatMessages(newChatMessages);

    const response = predefinedQuestions[message];
    if (response) {
      newChatMessages.push({ text: response, sender: 'admin' });
    }

    setChatMessages(newChatMessages);
    setNewMessage(''); // Clear the input field
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  const handleLogout = () => {
    window.location.href = '/'; // Redirect to the login page
  };
  const handleSaveProfile = () => {
    // You can send a request to update the instructor's profile information here.
    // For this example, we will just alert a success message.
    alert('Profile information saved!');
    setIsEditing(false); // Exit edit mode after saving
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="content1">
        <h1>Welcome to Instructor Dashboard</h1>
        <div className="dashboard">
          {/* Instructor Section */}
          <div className="course" style={{ maxWidth: '250px' }}>
            <h2>Instructor Profile</h2>
            {isEditing ? (
              <form>
                <label>
                  Name:
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
                <label>
                  Email:
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <label>
                  Department:
                  <input type="text" value={department} onChange={(e) => setDepartment(e.target.value)} />
                </label>
                <label>
                  Office Hours:
                  <input type="text" value={officeHours} onChange={(e) => setOfficeHours(e.target.value)} />
                </label>
                <button type="button" onClick={handleSaveProfile}>
                  Save
                </button>
              </form>
            ) : (
              <div>
                <p>
                  <strong>Name:</strong> {name}
                </p>
                <p>
                  <strong>Email:</strong> {email}
                </p>
                <p>
                  <strong>Department:</strong> {department}
                </p>
                <p>
                  <strong>Office Hours:</strong> {officeHours}
                </p>
                <button type="button" onClick={toggleEditMode}>
                  Edit
                </button>
              </div>
            )}
          
          </div>
          <div className="course">
            <div className="button-container1">
              <Link to="/GradePeers" className="button">
                Grade Peers
              </Link>
              <Link to="/managecourses" className="button">
                Course Creation
              </Link>
              <Link to="/CreateExam" className="button">
                Create Exam
              </Link>
            </div>
          </div>
          {/* Exam Dropdown */}
          <div className="course" style={{ maxWidth: '300px' }}>
            <h2>Exams</h2>
            <br></br>
            <select
              id="exam-dropdown"
              onChange={(e) => (window.location.href = e.target.value)}
            >
              <option value="#">Select an Exam</option>
              <option value="/Exam">Exam 1</option>
              <option value="/Exam">Exam 2</option>
              <option value="/Exam">Exam 3</option>
              <option value="/Exam">Exam 4</option>
              <option value="/Exam">Exam 5</option>
              {/* Add more exam options as needed */}
            </select>
            <br></br>
          </div>
          {/* Student Dropdown */}
          <div className="course" style={{ maxWidth: '300px' }}>
            <h2>Students</h2>
            <br></br>
            <select
              id="student-dropdown"
              onChange={(e) => (window.location.href = e.target.value)}
            >
              <option value="#">Select a Student</option>
              <option value="#">Student 1</option>
              <option value="#">Student 2</option>
              <option value="#">Student 3</option>
              <option value="#">Student 4</option>
              <option value="#">Student 5</option>
              {/* Add more student options as needed */}
            </select>
            <br></br>
          </div>
        </div>

        {/* Chat Box */}
        <div className="chat-box">
          <h2>Chat Box</h2>
          <div className="chat-messages">
            {chatMessages.map((message, index) => (
              <div key={index} className={`message from-${message.sender}`}>
                <span className="message-text">{message.text}</span>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              id="chat-input"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <button id="send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorDashboard;
