import React from 'react';
import './style.css'; // Import your CSS file

function ProgramCoordinatorManageStudents() {
  const handleAddStudent = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const name = e.target.querySelector('#student-name').value;
    const email = e.target.querySelector('#student-email').value;
    const department = e.target.querySelector('#student-department').value;

    // Add your logic for adding a student here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Student added successfully!\nName: ${name}\nEmail: ${email}\nDepartment: ${department}`);
  };

  const handleEditStudent = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const studentId = e.target.querySelector('#student-id').value;
    const newName = e.target.querySelector('#student-new-name').value;
    const newEmail = e.target.querySelector('#student-new-email').value;
    const newDepartment = e.target.querySelector('#student-new-department').value;

    // Add your logic for editing a student here.
    // For this example, we'll show an alert with the extracted data.
    alert(`Student edited successfully!\nID: ${studentId}\nNew Name: ${newName}\nNew Email: ${newEmail}\nNew Department: ${newDepartment}`);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Manage Students</h1><br />
          <p>Welcome to the Manage Students section for Program Coordinators.</p><br />

          {/* Sample content for managing students */}
          <h2>Add Student</h2><br />
          <form id="add-student-form" onSubmit={handleAddStudent}>
            <label htmlFor="student-name">Name:</label>
            <input type="text" id="student-name" name="name" required />
            <br />
            <label htmlFor="student-email">Email:</label>
            <input type="email" id="student-email" name="email" required />
            <br />
            <label htmlFor="student-department">Department:</label>
            <input type="text" id="student-department" name="department" required />
            <br />
            <button type="submit">Add Student</button>
          </form>
          <br />
          <h2>Edit Student</h2><br />
          <form onSubmit={handleEditStudent}>
            <label htmlFor="student-id">Select Student:</label>
            <select id="student-id" name="student-id">
              <option value="1">Student 1</option>
              <option value="2">Student 2</option>
              <option value="3">Student 3</option>
            </select>
            <br />
            <label htmlFor="student-new-name">New Name:</label>
            <input type="text" id="student-new-name" name="new-name" />
            <br />
            <label htmlFor="student-new-email">New Email:</label>
            <input type="email" id="student-new-email" name="new-email" />
            <br />
            <label htmlFor="student-new-department">New Department:</label>
            <input type="text" id="student-new-department" name="new-department" />
            <br />
            <button type="submit">Edit Student</button>
          </form>

          {/* Add more student management content as needed */}
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinatorManageStudents;
