import React from 'react';
import './style.css'; // Import your CSS file

function TakeAssessment() {
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    // You can add your assessment scoring logic here
    // For this example, we'll just display an alert
    alert('Assessment submitted. Thank you!');
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Take Assessment</h1>
          <p>Welcome to the assessment for Computer Science Course.</p>
          <br />

          <h2>Instructions:</h2>
          <ol>
            <li>Read each question carefully.</li>
            <li>Choose the correct answer.</li>
            <li>Click the "Submit" button when you are done.</li>
          </ol>
          <br />
          <br />

          {/* Sample assessment questions */}
          <form onSubmit={handleSubmit}>
            <p>
              <strong>Question 1:</strong> What is the primary function of an operating system?
            </p>
            <br />
            <label>
              <input type="radio" name="q1" value="manage-hardware" />
              Manage hardware resources
            </label>
            <br />
            <label>
              <input type="radio" name="q1" value="run-applications" />
              Run applications
            </label>
            <br />
            <label>
              <input type="radio" name="q1" value="send-emails" />
              Send emails
            </label>
            <br />
            <br />
            <br />

            <p>
              <strong>Question 2:</strong> What programming language is often used for web development?
            </p>
            <br />
            <label>
              <input type="radio" name="q2" value="java" />
              Java
            </label>
            <br />
            <label>
              <input type="radio" name="q2" value="python" />
              Python
            </label>
            <br />
            <label>
              <input type="radio" name="q2" value="javascript" />
              JavaScript
            </label>
            <br />
            <button type="submit">Submit Assessment</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TakeAssessment;
