import React from 'react';
import './style.css'; // Import your CSS file

function ProgramCoordinatorFeedback() {
  const handleFeedbackSubmission = () => {
    // You can add your logic for handling feedback submission here.

    // For this example, we'll show an alert.
    alert('Feedback submitted successfully!');
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Feedback</h1><br />
          <p>Welcome to the Feedback section for Program Coordinators.</p><br /><br />

          {/* Sample content for collecting feedback */}
          <h2>Feedback Collection</h2><br /><br />
          <form id="feedback-form" action="collect-feedback.html" method="post">
            <label htmlFor="feedback-type">Select Feedback Type:</label>
            <select id="feedback-type" name="feedback-type">
              <option value="course">Course Feedback</option>
              <option value="program">Program Feedback</option>
              <option value="instructor">Instructor Feedback</option>
            </select><br /><br />

            <label htmlFor="feedback-comments">Comments:</label>
            <textarea id="feedback-comments" name="comments" rows="4" cols="50" required></textarea>
            <br />
            <button type="button" onClick={handleFeedbackSubmission}>Submit Feedback</button>
          </form>

          {/* Add more feedback-related content as needed */}
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinatorFeedback;
