import React, { useState } from 'react';
import './style.css'; // Import your CSS file

function Exam() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Collect user's answers and calculate the score here
    // For this example, let's assume the user answered all questions correctly
    // You can add your logic to calculate the score

    // Display a confirmation message
    alert('Exam submitted successfully!');

    // Set the submitted state to true
    setSubmitted(true);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1> Exam</h1>
          <p>Welcome to the final exam for Computer Science Course.</p>
          <br />

          <h2>Instructions:</h2>
          <ol>
            <li>Read each question carefully.</li>
            <li>Choose the correct answer.</li>
            <li>Click the "Submit" button when you are done.</li>
          </ol>
          <br />
          <br />

          <form onSubmit={handleSubmit}>
            <p><strong>Question 1:</strong> What does HTML stand for?</p>
            <br />
            <label>
              <input type="radio" name="q1" value="hyperlink-text-markup-language" />
              Hyperlink Text Markup Language
            </label>
            <br />
            <label>
              <input type="radio" name="q1" value="hypertext-transfer-protocol" />
              Hypertext Transfer Protocol
            </label>
            <br />
            <label>
              <input type="radio" name="q1" value="hyper-text-makeup-language" />
              Hyper Text Makeup Language
            </label>
            <br />
            <br />
            <br />

            <p><strong>Question 2:</strong> What is the primary function of a database management system (DBMS)?</p>
            <br />
            <label>
              <input type="radio" name="q2" value="store-files-on-a-computer" />
              Store files on a computer
            </label>
            <br />
            <label>
              <input type="radio" name="q2" value="manage-database-operations" />
              Manage database operations
            </label>
            <br />
            <label>
              <input type="radio" name="q2" value="browse-the-internet" />
              Browse the internet
            </label>
            <br />
            <br />
            <br />

            {/* Add more questions here */}

            <button type="submit">Submit Exam</button>
          </form>

          {submitted && <p>Exam submitted. </p>}
        </div>
      </div>
    </div>
  );
}

export default Exam;
