import React, { useState } from 'react';
import './style.css'; // Import your CSS file
import forgotImage from './forgot.jpg'; // Import your image

function ForgotPassword() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Access the form data in formData.name and formData.email
    // You can add your logic here to handle the 'Forgot Password' functionality, like sending a reset email.

    // Reset the form fields
    setFormData({ name: '', email: '' });

    // Show an alert to the user
    window.alert('Password reset request submitted. Please check your email for further instructions.');
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="image">
            <img src={forgotImage} alt="Image Description" />
          </div>
          <div className="container">
            <center>
              <h2>Forgot Password</h2>
            </center>
            <br />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <button type="submit">Reset Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
