import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerReports() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Reports</h1>
          <br />
          <p>Welcome to the Reports section for QA Officers.</p>
          <br />

          {/* Sample report content */}
          <h2>Assessment Reports</h2>
          <p>You can access assessment reports and analytics here.</p>
          <br />
          <h2>Quality Assurance Reports</h2>
          <p>View quality assurance reports and program reviews.</p>

          {/* Add more report content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerReports;
