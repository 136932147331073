import React from 'react';
import './style.css'; // Import your CSS file
import { Link } from 'react-router-dom';

function Users() {
  const redirectToPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  return (
    <div>
      
      <div className="content1">
        <div className="center-grid">
          <br />
          <br />
          <br />
          <h1>Select User</h1>
          <br />
          <br />
          <br />
          <div className="button-container">
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/StudentDashboard" className="button">Student</Link>
            <Link to="/ProgramCoordinatorDashboard" className="button">Program Coordinator</Link>
            <Link to="/QaofficerDashboard" className="button">QA Officer</Link>
            <Link to="/InstructorDashboard" className="button">Instructor</Link>
            <Link to="/AdministratorDashboard" className="button">Administrator</Link>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default Users;
