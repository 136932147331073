import React from 'react';
import './style.css'; // Import your CSS file
import rightImage from './right-image.jpg'; // Import your image

function Home() {
  return (
    <div>
      
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="content3">
        <div className="left-div">
          <center>
            <div className="paragraph">
              <h1>Welcome to MSC Educational Management</h1>
              <p>
                Welcome to our website, MSC Educational Management.
              </p>
              <p>
                Our programs are thoughtfully designed to foster academic excellence, personal growth, and professional development. Whether you are a student seeking to expand your knowledge, an instructor dedicated to shaping the minds of future leaders, or an administrator working to enhance the educational experience.
              </p>
              <p>
                Explore our website to learn more about our academic programs, courses, and assessments.
              </p>
            </div>
          </center>
        </div>

        <div className="right-div">
          <img src={rightImage} alt="Right Image" />
        </div>
      </div>

      
    </div>
  );
}

export default Home;
