import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './style.css'; // Import your CSS file

function ProgramCoordinatorDashboard() {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const predefinedQuestions = {
    'Hi': 'Hello.',
    'How can I generate reports?': 'You can generate reports in the Reports section.',
    'Tell me about managing instructors.': 'Instructors can be managed in the Manage Instructors section.',
    'How do I handle student management?': 'Student management is available in the Manage Students section.',
    'What is the feedback process?': 'You can provide feedback in the Feedback section.',
    'How can I access program data?': 'Program data can be accessed through the Reports section.',
    'Tell me about instructor performance.': 'Instructor performance can be viewed in the Manage Instructors section.',
    'How to send notifications?': 'You can send notifications in the Manage Instructors section.',
    'How to access student records?': 'Student records can be accessed in the Manage Students section.',
    'Tell me about course scheduling.': 'Course scheduling is available in the Reports section.',
    'How to track program feedback?': 'Program feedback can be tracked in the Feedback section.',
    'Bye': 'Bye.Have a good day'
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return; // Don't send empty messages

    const newChatMessages = [...chatMessages];
    newChatMessages.push({ text: newMessage, sender: 'user' });
    setChatMessages(newChatMessages);

    const response = predefinedQuestions[newMessage];
    if (response) {
      newChatMessages.push({ text: response, sender: 'admin' });
    }

    setChatMessages(newChatMessages);
    setNewMessage(''); // Clear the input field
  };

  const handleLogout = () => {
    window.location.href = '/'; // Redirect to the login page
  };

  return (
    <div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="content1">
        <h1>Welcome to Program Coordinator Dashboard</h1>
        <div className="dashboard">
          <div className="section2">
            <h2>Program Coordinator</h2>
            <Link to="/ReportsQA" className="button">
              Reports
            </Link>&nbsp;<br></br><br></br><br></br>
            <Link to="/manageinstructors" className="button">
              Manage Instructors
            </Link>&nbsp;<br></br><br></br><br></br>
            <Link to="/managestudents" className="button">
              Manage Students
            </Link>&nbsp;<br></br><br></br><br></br>
            <Link to="/ProgramCoordinatorFeedback" className="button">
              Feedback
            </Link>
          </div>

          <div className="chat-box2">
            <h2>Chat Box</h2>
            <div className="chat-messages">
              {chatMessages.map((message, index) => (
                <div key={index} className={`message from-${message.sender}`}>
                  <span className="message-text">{message.text}</span>
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                id="chat-input"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <button id="send-button" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinatorDashboard;
