import React from 'react';
import './style.css'; // Import your CSS file
import progresschart from './progresschart.jpg'; 

function ViewAnalytics() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>View Analytics</h1>
          <br />
          <p>Explore the analytics for Computer Science Course to track your progress and performance.</p>
          <br />

          {/* Sample analytics charts or data */}
          <h2>Progress Chart</h2>
          <img src={progresschart} alt="Progress Chart" />
          <br />
          <h2>Performance Metrics</h2>
          <p>Your average quiz score: 82%</p>
          <p>Time spent on course material: 12 hours</p>
          <br />
          <h2>Engagement Analysis</h2>
          <p>Number of forum posts: 8</p>
          <p>Number of peer discussions: 5</p>
        </div>
      </div>
      
    </div>
  );
}

export default ViewAnalytics;
