import React, { useState, useEffect } from 'react';

function Analytics() {
  const [courseName, setCourseName] = useState('Course Name'); // Replace with actual course name
  const [studentName, setStudentName] = useState('Student Name'); // Replace with actual student name
  const [performanceData, setPerformanceData] = useState(null);

  useEffect(() => {
    // Simulated data for student performance in the course
    const sampleData = {
      quizzesTaken: 5,
      averageQuizScore: 85,
      assignmentsCompleted: 8,
      assignmentAverageScore: 92,
    };

    setPerformanceData(sampleData);
  }, []);

  return (
    <div>
      <h2>Course Analytics</h2>
      <h3>Course: {courseName}</h3>
      <h3>Student: {studentName}</h3>
      {performanceData && (
        <div>
          <h3>Performance Metrics</h3>
          <p>Quizzes Taken: {performanceData.quizzesTaken}</p>
          <p>Average Quiz Score: {performanceData.averageQuizScore}%</p>
          <p>Assignments Completed: {performanceData.assignmentsCompleted}</p>
          <p>Average Assignment Score: {performanceData.assignmentAverageScore}%</p>
        </div>
      )}
    </div>
  );
}

export default Analytics;
