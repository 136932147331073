import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './style.css'; // Import your CSS file
import courseImage from './course.jpg'; // Import your course image

function StudentDashboard() {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [name, setName] = useState('User');
  const [email, setEmail] = useState('User@example.com');
  const [department, setDepartment] = useState('Computer Science');
  const [enrollmentStatus, setEnrollmentStatus] = useState('Enrolled');
  const [isEditing, setIsEditing] = useState(false);
  const predefinedQuestions = {
    'Hi': 'Hello! How can I assist you today?',
    'Bye': 'Goodbye! If you have more questions, feel free to ask.',
    'How do I view course analytics?': 'You can view course analytics in the Analytics section.',
    'How to take an assessment?': 'You can take an assessment in the Take Assessment section.',
    'Where can I access reports?': 'Reports are available in the Reports section.',
    'Tell me about my profile': 'Your profile includes your name, email, department, and enrollment status.',
    'How to edit my profile?': 'You can edit your profile by clicking the "Edit Profile" button.',
    'How to access Course 1 analytics?': 'Course 1 analytics can be found in the Analytics section under Course 1.',
    'How to take an assessment for Course 1?': 'You can take the assessment for Course 1 in the Take Assessment section under Course 1.',
    'Where can I find reports for Course 1?': 'Reports for Course 1 are available in the Reports section under Course 1.',
    'Tell me about Course 2': 'Course 2 is another course you are enrolled in.',
    'How to view Course 2 analytics?': 'You can view Course 2 analytics in the Analytics section under Course 2.',
    'How to take an assessment for Course 2?': 'You can take the assessment for Course 2 in the Take Assessment section under Course 2.',
    'Where can I find reports for Course 2?': 'Reports for Course 2 are available in the Reports section under Course 2.',
    'How to navigate the dashboard?': 'You can use the buttons to access different sections of the dashboard.',
  };

  const sendMessage = (message) => {
    if (message.trim() === '') return; // Don't send empty messages

    const newChatMessages = [...chatMessages];
    newChatMessages.push({ text: message, sender: 'user' });
    setChatMessages(newChatMessages);

    const response = predefinedQuestions[message];
    if (response) {
      newChatMessages.push({ text: response, sender: 'admin' });
    }

    setChatMessages(newChatMessages);
    setNewMessage(''); // Clear the input field
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  const handleLogout = () => {
    window.location.href = '/'; // Redirect to the login page
  };
  const handleSaveProfile = () => {
    // You can send a request to update the user's profile information here.
    // For this example, we will just alert a success message.
    alert('Profile information saved!');
    setIsEditing(false); // Exit edit mode after saving
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="content1">
        <h1>Welcome to Student Dashboard</h1>
        <div className="dashboard">
        <div className="course" style={{ maxWidth: '300px' }}>
            <h2>Student Profile</h2>
            {isEditing ? (
              <form>
                <label>
                  Name:
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
                <label>
                  Email:
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <label>
                  Department:
                  <input type="text" value={department} onChange={(e) => setDepartment(e.target.value)} />
                </label>
                <label>
                  Enrollment Status:
                  <input type="text" value={enrollmentStatus} onChange={(e) => setEnrollmentStatus(e.target.value)} />
                </label>
                <button type="button" onClick={handleSaveProfile}>
                  Save
                </button>
              </form>
            ) : (
              <div>
                <p>
                  <strong>Name:</strong> {name}
                </p>
                <p>
                  <strong>Email:</strong> {email}
                </p>
                <p>
                  <strong>Department:</strong> {department}
                </p>
                <p>
                  <strong>Enrollment Status:</strong> {enrollmentStatus}
                </p>
                <button type="button" onClick={toggleEditMode}>
                  Edit
                </button>
              </div>
            )}
          </div>

          <div className="course" style={{ maxWidth: '300px' }}>
            <img src={courseImage} alt="Course 1" />
            <h3>Course 1</h3><br></br>
            <div>
              <Link to="/ViewAnalytics" className="button">
                Analytics
              </Link><br></br><br></br><br></br>
              <Link to="/TakeAssessment" className="button">
                Take Assessment
              </Link><br></br><br></br><br></br>
              <Link to="/ReportStudent" className="button">
                Reports
              </Link>
            </div>
          </div>
          <div className="course" style={{ maxWidth: '300px' }}>
            <img src={courseImage} alt="Course 2" />
            <h3>Course 2</h3><br></br>
            <div>
              <Link to="/ViewAnalytics" className="button">
                Analytics
              </Link><br></br><br></br><br></br>
              <Link to="/TakeAssessment" className="button">
                Take Assessment
              </Link><br></br><br></br><br></br>
              <Link to="/ReportStudent" className="button">
                Reports
              </Link>
            </div>
          </div>
          <div className="course" style={{ maxWidth: '300px' }}>
            <img src={courseImage} alt="Course 3" />
            <h3>Course 3</h3><br></br>
            <div>
              <Link to="/ViewAnalytics" className="button">
                Analytics
              </Link><br></br><br></br><br></br>
              <Link to="/TakeAssessment" className="button">
                Take Assessment
              </Link><br></br><br></br><br></br>
              <Link to="/ReportStudent" className="button">
                Reports
              </Link>
            </div>
          </div>
        </div>

        <div className="chat-box">
          <h2>Chat Box</h2>
          <div className="chat-messages">
            {chatMessages.map((message, index) => (
              <div key={index} className={`message from-${message.sender}`}>
                <span className="message-text">{message.text}</span>
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              id="chat-input"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <button id="send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDashboard;
