import React from 'react';
import imageurl from './imageurl.jpg';

function Blog() {
  const imageUrl = 'http://sxc1573.uta.cloud/wdm/uncategorized/blog-for-msc-educational-management/'; // Replace with the URL you want to link to

  return (
    <div>
      <br></br><br></br>
      <center><h2>Blog Post</h2><br></br>
      <p>Blog Content is here ...Please Click on Image</p></center><br></br>
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <center><img src={imageurl} alt="Blog Post" className="centered-image" /></center>
      </a>
    </div>
  );
}

export default Blog;
