import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerAssessmentTools() {
  const openAssessmentSoftware = () => {
    alert('Opening Assessment Software...');
    // Add your logic here to open assessment software.
  };

  const openQuestionBank = () => {
    alert('Opening Question Bank...');
    // Add your logic here to open the question bank.
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Tools for Assessment</h1>
          <br />
          <p>Welcome to the Tools for Assessment section for QA Officers.</p>
          <br />

          {/* Sample assessment tools content */}
          <h2>Assessment Software</h2>
          <p>Access assessment software and tools for evaluation.</p>
          <button className="button" onClick={openAssessmentSoftware}>
            Open Assessment Software
          </button>

          <br />

          <h2>Question Bank</h2>
          <p>Explore a question bank for creating assessments.</p>
          <button className="button" onClick={openQuestionBank}>
            Open Question Bank
          </button>

          {/* Add more assessment tools content as needed */}
        </div>
      </div>
    </div>
  );
}

export default QaOfficerAssessmentTools;
