import React from 'react';
import './style.css'; // Import your CSS file

function ViewReports() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>View Reports</h1>
          <br />
          <p>Here you can view your progress and performance reports for Computer Science Course.</p>
          <br />

          {/* Sample reports */}
          <h2>Course Progress</h2>
          <br />

          <p>You have completed 70% of the course material.</p>
          <br />

          <h2>Assessment Results</h2>
          <table>
            <tr>
              <th>Assessment</th>
              <th>Score</th>
            </tr>
            <br />
            <tr>
              <td>Quiz 1</td>
              <td>85%</td>
            </tr>
            <br />
            <tr>
              <td>Quiz 2</td>
              <td>78%</td>
            </tr>
          </table>
          <br />
          <h2>Feedback</h2>
          <p>If you have any feedback or questions, please contact your instructor.</p>
        </div>
      </div>
     
    </div>
  );
}

export default ViewReports;
