import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import './style.css'; // Import the CSS file
import Home from './components/index.jsx';
import About from './components/About.jsx';
import Blog from './components/Blog.jsx';
import Services from './components/Services.jsx';
import SignIn from './components/SignIn.jsx';
import SignUp from './components/SignUp.jsx';
import Contact from './components/Contact.jsx';
import AdministratorManageStudents from './components/AdministratorManageStudents.jsx';
import AdministratorReviews from './components/AdministratorReviews.jsx';
import AdminPermissions from './components/AdminPermissions.jsx';
import AdminAssignedCourse from './components/AdminAssignedCourse.jsx';
import AdministratorAssignedStudents from'./components/AdministratorAssignedStudents.jsx';
import AdministratorCreateCourse from'./components/AdministratorCreateCourse.jsx';
import AdministratorDashboard from'./components/AdministratorDashboard.jsx';
import CreateCourse from './components/CreateCourse.jsx';
import CreateExam from './components/CreateExam.jsx';
import Edit from './components/Edit.jsx';
import EditStudentProfile from './components/EditStudentProfile.jsx';
import Exam from './components/Exam.jsx';
import ForgotPassword from './components/ForgotPassword.jsx';
import GradePeers from './components/GradePeers.jsx';
import InstructorDashboard from'./components/InstructorDashboard.jsx';
import StudentDashboard from'./components/StudentDashboard.jsx';
import ProgramCoordinatorDashboard from'./components/ProgramCoordinatotDashboard.jsx';
import ProgramCoordinatorFeedback from'./components/ProgramCoordinatorFeedback.jsx';
import ProgramCoordinatorReports from'./components/ProgramCoordinatorReports.jsx';
import ProgramCoordinatorManageInstructors from'./components/ProgramCoordinatorManageInstructors.jsx';
import ProgramCoordinatorManageStudents from'./components/ProgramCoordinatorManageStudents.jsx';
import QaOfficerAssessmentTools from'./components/QaOfficerAssessmentTools.jsx';
import QaOfficerFacultyDevelopment from'./components/QaOfficerFacultyDevelopment.jsx';
import QaOfficerReports from'./components/QaOfficerReports.jsx';
import QaOfficerQualityStandards from'./components/QaOfficerQualityStandards.jsx';
import QaOfficerProgramReview from'./components/QaOfficerProgramReview.jsx';
import QaOfficerFeedback from'./components/QaOfficerFeedback.jsx';
import QaOfficerImprovement from'./components/QaOfficerImprovement.jsx';
import QAOfficerManageCourses from'./components/QAOfficerManageCourses.jsx';
import QAOfficerManageOfficers from'./components/QAOfficerManageOfficers.jsx';
import QaofficerDashboard from'./components/QaofficerDashboard.jsx';
import QaOfficerStudentSupport from'./components/QaOfficerStudentSupport.jsx';
import TakeAssessment from'./components/TakeAssessment.jsx';
import Users from'./components/Users.jsx';
import ViewAnalytics from'./components/ViewAnalytics.jsx';
import ViewReports from'./components/ViewReports.jsx';

import ManageInstructors from './components/ManageInstructors';
import ManageStudents from './components/ManageStudents';
import ManageCourses from './components/ManageCourse';
import ManageQA from './components/ManageQA';

import AddInstructor from './components/AddInstructor.jsx';
import EditInstructor from './components/EditInstructor.jsx';
import AddStudent from './components/AddStudent.jsx';
import EditStudent from './components/EditStudent.jsx';
import AddQA from './components/Addqa.jsx';
import EditQA from './components/Editqa.jsx';
import AddCourse from './components/AddCourse.jsx';
import EditCourse from './components/EditCourse.jsx';
import Header from './components/Header';
import Report_S from './components/Report_S';
import Analytics from './components/Analytics';
import AssignedStudents from './components/AssignedStudents';
import Permissions from './components/Permissions';
import ReportQA from './components/ReportQA';
import ReportStudent from './components/ReportStudent';
import Performance from './components/Performance';

function App() {
   return (
    <Router>
       <div>
        {/* Header Section */}
        <header>
          <div className="navbar">
            <div className="logo">
              <h2>MSC Educational Management</h2>
            </div>
            <nav id="navbar">
              <ul>
                <li><NavLink exact to="/">Home</NavLink></li>
			<li><NavLink to="/Blog">Blog</NavLink></li>
                <li><NavLink to="/About">About Us</NavLink></li>
                <li><NavLink to="/Services">Services</NavLink></li>
                <li><NavLink to="/SignIn">Login</NavLink></li>
                <li><NavLink to="/Contact">Contact Us</NavLink></li>
              </ul>
            </nav>
          </div>
        </header>        
        <Switch>
  <Route exact path="/" component={Home} />
  <Route path="/About" component={About} />
  <Route path="/Services" component={Services} />
  <Route path="/SignIn" component={SignIn} />
  <Route path="/Contact" component={Contact} />
  <Route path="/SignUp" component={SignUp} />
<Route path="/Blog" component={Blog} />

<Route exact path="/manageinstructors" component={ManageInstructors} /> {/* Add a route for the "ManageInstructors" component */}
  <Route exact path="/managestudents" component={ManageStudents} /> {/* Add a route for the "ManageStudents" component */}
  <Route exact path="/managecourses" component={ManageCourses} /> {/* Add a route for the "ManageCourses" component */}
  <Route exact path="/manageqa" component={ManageQA} /> {/* Add a route for the "ManageQA" component */}
  <Route path="/Report_S" component={Report_S} />
<Route path="/Analytics" component={Analytics} />
<Route path="/AssignedStudents" component={AssignedStudents} />
<Route path="/Permissions" component={Permissions} />
<Route path="/ReportQA" component={ReportQA} />
<Route path="/ReportStudent" component={ReportStudent} />
<Route path="/Performance" component={Performance} />

  <Route exact path="/addinstructor" component={AddInstructor} /> {/* Add a route for the "AddInstructor" component */}
  <Route exact path="/editinstructor" component={EditInstructor} /> {/* Add a route for the "EditInstructor" component */}
  <Route exact path="/addstudent" component={AddStudent} /> {/* Add a route for the "AddStudent" component */}
  <Route exact path="/editstudent" component={EditStudent} /> {/* Add a route for the "EditStudent" component */}
  <Route exact path="/addqa" component={AddQA} /> {/* Add a route for the "AddQA" component */}
  <Route exact path="/editqa" component={EditQA} /> {/* Add a route for the "EditQA" component */}
  <Route exact path="/addcourse" component={AddCourse} /> {/* Add a route for the "AddCourse" component */}
  <Route exact path="/editcourse" component={EditCourse} /> {/* Add a route for the "EditCourse" component */}
  {/* Add more routes for other pages as needed */}


  <Route path="/QAOfficerManageCourses" component={QAOfficerManageCourses} />
<Route path="/QAOfficerManageOfficers" component={QAOfficerManageOfficers} />
<Route path="/AdministratorManageStudents" component={AdministratorManageStudents} />
  <Route path="/AdministratorReviews" component={AdministratorReviews} />
  <Route path="/AdminPermissions" component={AdminPermissions} />
  <Route path="/AdminAssignedCourse" component={AdminAssignedCourse} />
  <Route path="/AdministratorAssignedStudents" component={AdministratorAssignedStudents} />
  <Route path="/AdministratorCreateCourse" component={AdministratorCreateCourse} />
  <Route path="/AdministratorDashboard" component={AdministratorDashboard} />
  <Route path="/CreateCourse" component={CreateCourse} />
  <Route path="/CreateExam" component={CreateExam} />
  <Route path="/Edit" component={Edit} />
  <Route path="/EditStudentProfile" component={EditStudentProfile} />
  <Route path="/Exam" component={Exam} />
  <Route path="/ForgotPassword" component={ForgotPassword} />
  <Route path="/GradePeers" component={GradePeers} />
  <Route path="/InstructorDashboard" component={InstructorDashboard} />
  <Route path="/StudentDashboard" component={StudentDashboard} />
  <Route path="/ProgramCoordinatorDashboard" component={ProgramCoordinatorDashboard} />
  <Route path="/ProgramCoordinatorFeedback" component={ProgramCoordinatorFeedback} />
<Route exact path="/manageinstructors" component={ManageInstructors} /> {/* Add a route for the "ManageInstructors" component */}
  <Route exact path="/managestudents" component={ManageStudents} /> {/* Add a route for the "ManageStudents" component */}
  <Route exact path="/managecourses" component={ManageCourses} /> {/* Add a route for the "ManageCourses" component */}
  <Route exact path="/manageqa" component={ManageQA} /> {/* Add a route for the "ManageQA" component */}
  
  <Route exact path="/addinstructor" component={AddInstructor} /> {/* Add a route for the "AddInstructor" component */}
  <Route exact path="/editinstructor" component={EditInstructor} /> {/* Add a route for the "EditInstructor" component */}
  <Route exact path="/addstudent" component={AddStudent} /> {/* Add a route for the "AddStudent" component */}
  <Route exact path="/editstudent" component={EditStudent} /> {/* Add a route for the "EditStudent" component */}
  <Route exact path="/addqa" component={AddQA} /> {/* Add a route for the "AddQA" component */}
  <Route exact path="/editqa" component={EditQA} /> {/* Add a route for the "EditQA" component */}
  <Route exact path="/addcourse" component={AddCourse} /> {/* Add a route for the "AddCourse" component */}
  <Route exact path="/editcourse" component={EditCourse} /> {/* Add a route for the "EditCourse" component */}
  {/* Add more routes for other pages as needed */}


  <Route path="/ProgramCoordinatorReports" component={ProgramCoordinatorReports} />
  <Route path="/ProgramCoordinatorManageInstructors" component={ProgramCoordinatorManageInstructors} />
  <Route path="/ProgramCoordinatorManageStudents" component={ProgramCoordinatorManageStudents} />
  <Route path="/QaOfficerAssessmentTools" component={QaOfficerAssessmentTools} />
  <Route path="/QaOfficerFacultyDevelopment" component={QaOfficerFacultyDevelopment} />
  <Route path="/QaOfficerReports" component={QaOfficerReports} />
  <Route path="/QaOfficerQualityStandards" component={QaOfficerQualityStandards} />
  <Route path="/QaOfficerProgramReview" component={QaOfficerProgramReview} />
  <Route path="/QaOfficerFeedback" component={QaOfficerFeedback} />
 <Route path="/QaOfficerImprovement" component={QaOfficerImprovement} />
  <Route path="/QaofficerDashboard" component={QaofficerDashboard} />
  <Route path="/QaOfficerStudentSupport" component={QaOfficerStudentSupport} />
  <Route path="/TakeAssessment" component={TakeAssessment} />
  <Route path="/Users" component={Users} />
  <Route path="/ViewAnalytics" component={ViewAnalytics} />
 <Route path="/Header" component={Header} />
  <Route path="/ViewReports" component={ViewReports} />
</Switch>

        {/* Footer Section */}
        <footer>
          <p>&copy; 2023 Msc Educational Management</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
