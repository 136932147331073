import React, { useState, useEffect } from 'react';
import Addqa from './Addqa';
import Editqa from './Editqa';

function Manageqa() {
  const [qa, setqa] = useState([]);


  useEffect(() => {
    // Fetch the list of qa to populate the list
    fetch('https://sxg7864.uta.cloud/phase3-php/retrieve-qa.php')
      .then((response) => response.json())
      .then((data) => {
        setqa(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <center><h1>Manage qa</h1></center>

      <Addqa refreshqa={() => {
        // Implement a function to refresh the qa list after adding or editing
        fetch('https://sxg7864.uta.cloud/phase3-php/retrieve-qa.php')
          .then((response) => response.json())
          .then((data) => {
            setqa(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }} />

      

      {/* Render the list of qa */}
      <ul>
  {qa ? (
    qa.map((qa) => (
      <li key={qa.id}>
        {qa.name} - {qa.email} - {qa.qa}
      </li>
    ))
  ) : (
    <li>No qa available</li>
  )}
</ul>

    </div>
  );
}

export default Manageqa;
