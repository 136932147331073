import React from 'react';
import './style.css'; // Import your CSS file

function GradePeers() {
  const calculateGrades = () => {
    // Your logic to calculate and save grades can go here.
    // For demonstration purposes, let's assume you're saving grades.

    // Simulate saving grades (you can replace this with your actual logic)
    alert('Grades saved successfully!');
  };

  return (
    <div>
      
      <div className="container">
        <h2>Grade Peers</h2>
        <div className="content1">
          <table>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>Grade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Student 1</td>
                <td><input type="text" id="grade1" /></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Student 2</td>
                <td><input type="text" id="grade2" /></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Student 3</td>
                <td><input type="text" id="grade3" /></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Student 4</td>
                <td><input type="text" id="grade4" /></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Student 5</td>
                <td><input type="text" id="grade5" /></td>
              </tr>
            </tbody>
          </table>
          <button className="button" onClick={calculateGrades}>Save grades</button>
        </div>
      </div>
      <footer>
        <p>&copy; 2023 Instructor Dashboard</p>
      </footer>
    </div>
  );
}

export default GradePeers;
