import React from 'react';
import './style.css'; // Import your CSS file

function QaOfficerImprovement() {
  return (
    <div>
      
      <div className="container">
        <div className="content1">
          <h1>Improvement</h1>
          <br />
          <p>Welcome to the Improvement section for QA Officers.</p>
          <br />

          {/* Sample improvement content */}
          <h2>Action Plans</h2>
          <p>Create and manage action plans based on feedback and reports.</p>
          <br />
          <h2>Quality Enhancement</h2>
          <p>Focus on continuous quality enhancement for educational programs.</p>

          {/* Add more improvement content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default QaOfficerImprovement;
