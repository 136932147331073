import React from 'react';
import './style.css'; // Import your CSS file

function QAOfficerManageOfficers() {
  const handleAddOfficer = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const name = e.target.querySelector('#officer-name').value;
    const email = e.target.querySelector('#officer-email').value;
    const department = e.target.querySelector('#officer-department').value;

    // Add your logic for adding a QA Officer here.
    // For this example, we'll show an alert with the extracted data.
    alert(`QA Officer added successfully!\nName: ${name}\nEmail: ${email}\nDepartment: ${department}`);
  };

  const handleEditOfficer = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting.

    // Extract the form data
    const officerId = e.target.querySelector('#officer-id').value;
    const newName = e.target.querySelector('#officer-new-name').value;
    const newEmail = e.target.querySelector('#officer-new-email').value;
    const newDepartment = e.target.querySelector('#officer-new-department').value;

    // Add your logic for editing a QA Officer here.
    // For this example, we'll show an alert with the extracted data.
    alert(`QA Officer edited successfully!\nID: ${officerId}\nNew Name: ${newName}\nNew Email: ${newEmail}\nNew Department: ${newDepartment}`);
  };

  return (
    <div>
      <div className="container">
        <div className="content1">
          <h1>Manage QA Officers</h1><br />
          <p>Welcome to the Manage QA Officers section for QA Officers.</p><br />

          {/* Sample content for managing QA Officers */}
          <h2>Add QA Officer</h2><br />
          <form id="add-officer-form" onSubmit={handleAddOfficer}>
            <label htmlFor="officer-name">Name:</label>
            <input type="text" id="officer-name" name="name" required />
            <br />
            <label htmlFor="officer-email">Email:</label>
            <input type="email" id="officer-email" name="email" required />
            <br />
            <label htmlFor="officer-department">Department:</label>
            <input type="text" id="officer-department" name="department" required />
            <br />
            <button type="submit">Add QA Officer</button>
          </form>
          <br />
          <h2>Edit QA Officer</h2><br />
          <form onSubmit={handleEditOfficer}>
            <label htmlFor="officer-id">Select QA Officer:</label>
            <select id="officer-id" name="officer-id">
              <option value="1">QA Officer 1</option>
              <option value="2">QA Officer 2</option>
              <option value="3">QA Officer 3</option>
            </select>
            <br />
            <label htmlFor="officer-new-name">New Name:</label>
            <input type="text" id="officer-new-name" name="new-name" />
            <br />
            <label htmlFor="officer-new-email">New Email:</label>
            <input type="email" id="officer-new-email" name="new-email" />
            <br />
            <label htmlFor="officer-new-department">New Department:</label>
            <input type="text" id="officer-new-department" name="new-department" />
            <br />
            <button type="submit">Edit QA Officer</button>
          </form>

          {/* Add more QA Officer management content as needed */}
        </div>
      </div>
    </div>
  );
}

export default QAOfficerManageOfficers;
