import React from 'react';
import './style.css'; // Import your CSS file

function AdministratorManageStudents() {
  // Sample student data for the select dropdown
  const studentData = [
    { id: 1, name: 'Student 1' },
    { id: 2, name: 'Student 2' },
    { id: 3, name: 'Student 3' },
  ];

  // Function to handle adding a student
  const handleAddStudent = (e) => {
    e.preventDefault();
    const name = e.target['name'].value;
    const email = e.target['email'].value;
    const department = e.target['department'].value;

    // Show an alert with the form data
    alert('Add Student Form Data:\nName: ' + name + '\nEmail: ' + email + '\nDepartment: ' + department);
  };

  // Function to handle editing a student
  const handleEditStudent = (e) => {
    e.preventDefault();
    const studentId = e.target['student-id'].value;
    const newName = e.target['new-name'].value;
    const newEmail = e.target['new-email'].value;
    const newDepartment = e.target['new-department'].value;

    // Show an alert with the form data
    alert('Edit Student Form Data:\nStudent ID: ' + studentId + '\nNew Name: ' + newName + '\nNew Email: ' + newEmail + '\nNew Department: ' + newDepartment);
  };

  return (
    <div>
     
      <div className="container">
        <div className="content1">
          <h1>Manage Students</h1><br />
          <p>Welcome to the Manage Students section for Administrators.</p><br />
          {/* Sample content for managing students */}
          <h2>Add Student</h2><br />
          <form id="add-student-form" onSubmit={handleAddStudent}>
            <label htmlFor="student-name">Name:</label>
            <input type="text" id="student-name" name="name" required />
            <br />
            <label htmlFor="student-email">Email:</label>
            <input type="email" id="student-email" name="email" required />
            <br />
            <label htmlFor="student-department">Department:</label>
            <input type="text" id="student-department" name="department" required />
            <br />
            <button type="submit">Add Student</button>
          </form>
          <br />
          <h2>Edit Student</h2><br />
          <form onSubmit={handleEditStudent}>
            <label htmlFor="student-id">Select Student:</label>
            <select id="student-id" name="student-id">
              {studentData.map((student) => (
                <option key={student.id} value={student.id}>
                  {student.name}
                </option>
              ))}
            </select>
            <br />
            <label htmlFor="new-name">New Name:</label>
            <input type="text" id="new-name" name="new-name" />
            <br />
            <label htmlFor="new-email">New Email:</label>
            <input type="email" id="new-email" name="new-email" />
            <br />
            <label htmlFor="new-department">New Department:</label>
            <input type="text" id="new-department" name="new-department" />
            <br />
            <button type="submit">Edit Student</button>
          </form>
          {/* Add more student management content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default AdministratorManageStudents;
