import React from 'react';
import { Link } from 'react-router-dom';
function Header() {
  return (
    <header>
          <div className="navbar">
            <div className="logo">
              <h2>MSC Educational Management</h2>
            </div>
            <nav id="navbar">
              <ul>
                <li><Link exact to="/">Home</Link></li>
			<li><Link to="/Blog">Blog</Link></li>
                <li><Link to="/About">About Us</Link></li>
                <li><Link to="/Services">Services</Link></li>
                <li><Link to="/SignIn">Login</Link></li>
                <li><Link to="/Contact">Contact Us</Link></li>
              </ul>
            </nav>
          </div>
        </header>

  );
}

export default Header;
